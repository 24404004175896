.skillSection{
  display:flex;
  align-items: stretch;
  justify-content: center;
  background-color: var(--color-minor-2);
  flex-wrap: wrap;
  box-shadow: 0 1vh 1vh -0.5vh black;
}


.skillCat{
margin: 1vw;
padding: 0vw 1vw 0vw 1vw;
text-align: left;
width: 40vw;
border-radius: 1vw;
background-color: var(--color-minor);
box-shadow: 0vh 0vh 1vh black;
}

@media screen and (min-width: 768px) {
.skillCat{
  width:25vw;
}
}

@media screen and (min-width: 992px) {
.skillCat{
  width:15vw;
}
}
