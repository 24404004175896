.navBar {
    width: 100%;
    background-color: var(--color-accent);
    text-align: center;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    position: sticky;
    top: 0;
    box-shadow: 0 1vh 1vh -0.5vh black;
}

nav li {
  padding: 1vh 1vw;
  color: black;
}

nav a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

nav li:hover {
  background: var(--color-accent-2);
  color: white;
}

button {
    font-size: 1.5em;
    color: black;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    height: auto;
    padding: 2vh;
}

button:hover{
  background-color: var(--color-accent-2);
  color:white;
}


.links {
  height: 0;
  width: 90vw;
  overflow: hidden;
  list-style-type: none;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin: 0;

}

.showLinks{
  composes:links;
  height:auto;
}


@media screen and (min-width: 768px) {
    button {
        display: none;
    }

    .links {
        height: auto;
        flex-direction: row;
        width: auto;
    }
    nav li{
      padding: 1vh 3vw;
    }

}
