.collapsibleSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.collapsible{
  white-space: pre-line;
  margin-top: 1vh;
  width: 90vw;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.collapsibleOpen{
  composes: collapsible;
}

.collapsibleTrigger{
    background-color: var(--color-accent);
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;


}

.collapsibleTrigger:hover{
  background-color: var(--color-accent-2);

}

.collapsibleTriggerOpen{
  composes: collapsibleTrigger
}

.collapsibleTrigger:after {
  content: '\25BD';
  float: right;
  position: relative;
  left: -2vw;
  display: inline;}

.collapsibleTriggerOpen:after {
  content: '\25B3';
  display: inline; }

.collapsibleOuter{
  width: 90vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: var(--color-minor-2);
  margin: auto;
}

.collapsibleInner{
  composes:collapsibleOuter;
}
