.buttonLink{
  font-size: 1em;
  color: white;
  background: var(--color-accent-2);
  border: none;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  height: auto;
  padding: 2vh;
  width: auto;
  border-radius: 0.5vw;
  text-align: center;
  justify-content: center;
  box-shadow: 0vh 0vh 1vh black;
}

.buttonLink:hover{
  background: var(--color-accent);
  color: black;
}

a {
  width: 10px;
}
