.cocktailSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.cocktailImg{
  composes: artImg from "./ArtSection.module.css"
}
.cocktailCategory{
  composes:artCollection from "./ArtSection.module.css"
}
.cocktailItem{
  composes:artItem from "./ArtSection.module.css";
  width:40vw;
}

.cocktailComponentList{
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-basis: min-content;
}

.collapsible{
  composes:collapsible from "./ArtSection.module.css"
}

.collapsibleOpen{
  composes:collapsibleOpen from "./ArtSection.module.css"
}

.collapsibleTrigger{
    composes:collapsibleTrigger from "./ArtSection.module.css"
}

.collapsibleTriggerOpen{
  composes:collapsibleTriggerOpen from "./ArtSection.module.css"
}

.collapsibleTrigger:after {
  content: '\25BD';
  display: inline;}

.collapsibleTriggerOpen:after {
  content: '\25B3';
  display: inline; }

.collapsibleOuter{
  composes:collapsibleOuter from "./ArtSection.module.css"
}

.collapsibleInner{
  composes:collapsibleInner from "./ArtSection.module.css"
}

@media screen and (max-width: 768px) {
  .cocktailItem{
    width:80vw;
  }
}
