.artHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.artSection p{
  text-align: left;
  padding-left: 5vw;
  width: 70%;
}

.artImg{
  max-width: 100%;
  max-height: 60vh;
  margin: auto;
  display: block;
  object-fit: scale-down;
  image-orientation: from-image;
}

.artCollection{
  display: flex;
  flex-direction: row;
  max-width: 90vw;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.artItem{
  width: 30vw;
  height: 100%;
  background-color:  var(--color-minor);
  border-radius: 2vw;
  margin: 1vw;
  box-shadow: 0vh 0vh 2vh black;
}

.collapsible{
  white-space: pre-line;
  margin-top: 1vh;
  width: 90vw;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.collapsibleOpen{
  composes: collapsible;
}

.collapsibleTrigger{
    background-color: var(--color-accent);
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;


}

.collapsibleTrigger:hover{
  background-color: var(--color-accent-2);

}

.collapsibleTriggerOpen{
  composes: collapsibleTrigger
}

.collapsibleTrigger:after {
  content: '\25BD';
  float: right;
  position: relative;
  left: -2vw;
  display: inline;}

.collapsibleTriggerOpen:after {
  content: '\25B3';
  display: inline; }

.collapsibleOuter{
  width: 90vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: var(--color-minor-2);
  margin: auto;
}

.collapsibleInner{
  composes:collapsibleOuter;
}

@media screen and (max-width: 768px) {
  .artItem{
    width:80vw;
  }
  .artImg{
    height:50vh;
  }
}
