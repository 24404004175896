.sectionTitle{
  justify-content: center;
  text-align: left;
  font-size:2em;
  background-color: var(--color-accent-2);
  padding: 1vh 0 1vh 3vw;
  margin: 2vh 0 2vh 0;
  font-weight: bold;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.productSection{
  align-items: left;
  display: flex;
  flex-direction: column;
}

.productItem{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.productLeftZone{
  width: 30vw;
  min-height:350px;
  display: flex;
  align-items: flex-end;
  background-color: black;
}

.productImg {
  max-width: 100%;
  height:auto;
  margin: auto 0 auto auto;
  display: block;
  object-fit: contain;
  background-color: black;
}

.productContent{
  padding-left: 1vw;
  width: 60vw;
  text-align: left;
  border-bottom: var(--color-accent-2) solid 1px;
}

.productLink{
  color:black;
  text-decoration: none;
}

.productLink:hover{
  color:var(--color-minor-2);
}

@media screen and (max-width: 768px) {
  .productContent{
    width: 100vw;
  }
  .productLeftZone{
    width:100vw;
    height: 30vh;
    align-items: center;
  }
  .productImg{
    margin: auto;
    height:100%;
  }
}
