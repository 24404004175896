@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Heading_banner__1XAyj{
  display:flex;
  background-color: RoyalBlue;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/me_filter_1.0558c8ff.jpg);
  background-size: 20vw;
  background-repeat: repeat;
  height: 60vh;
  box-shadow: 0 1vh 1vh -0.5vh black;
  margin: 0 0 1vh 0;
}

.Heading_headText__3Cjf2{
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1vh;
  margin: 1vh;
  color: white;
  background-color: var(--color-accent-2);
  border-radius: 1vh;
  box-shadow: 0vh 0vh 2vh 1vh black;
}


@media screen and (max-width: 768px) {
  .Heading_banner__1XAyj{
    background-size: 15vw;
    height: 40vh;
  }

}

.PageIntro_introSection__2zAHy{
  white-space: pre-line;
}

.PageIntro_introImg__3aI4o{
  width:100vw;
  height: 30vh;
  display: block;
  object-fit: cover;
  box-shadow: 0 1vh 1vh -0.5vh black;
  margin: 0 0 1vh 0;
}

.PageIntro_textSection__UR6Ad{
  width: 60vw;
  margin: auto;
}

.PageIntro_introSection__2zAHy h1{
  text-align: left;
}

.PageIntro_introSub__12hNB{
  padding: 0
}

@media screen and (max-width: 768px) {

.PageIntro_textSection__UR6Ad{
  width: 95vw;
}

.PageIntro_introImg__3aI4o{
  height: 20vh;
}
}

.ExtButton_buttonLink__2UVPx{
  font-size: 1em;
  color: white;
  background: var(--color-accent-2);
  border: none;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  height: auto;
  padding: 2vh;
  width: auto;
  border-radius: 0.5vw;
  text-align: center;
  justify-content: center;
  box-shadow: 0vh 0vh 1vh black;
}

.ExtButton_buttonLink__2UVPx:hover{
  background: var(--color-accent);
  color: black;
}

a {
  width: 10px;
}

.CollapsibleSection_collapsibleSection__2MZBl{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.CollapsibleSection_collapsible__2K-I4{
  white-space: pre-line;
  margin-top: 1vh;
  width: 90vw;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.CollapsibleSection_collapsibleOpen__2QgIH{
}

.CollapsibleSection_collapsibleTrigger__5-4jr{
    background-color: var(--color-accent);
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;


}

.CollapsibleSection_collapsibleTrigger__5-4jr:hover{
  background-color: var(--color-accent-2);

}

.CollapsibleSection_collapsibleTriggerOpen__3CHOZ{
}

.CollapsibleSection_collapsibleTrigger__5-4jr:after {
  content: '\25BD';
  float: right;
  position: relative;
  left: -2vw;
  display: inline;}

.CollapsibleSection_collapsibleTriggerOpen__3CHOZ:after {
  content: '\25B3';
  display: inline; }

.CollapsibleSection_collapsibleOuter__1Q7Al{
  width: 90vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: var(--color-minor-2);
  margin: auto;
}

.CollapsibleSection_collapsibleInner__2vnkm{
}

.SkillsSection_skillSection__1vAll{
  display:flex;
  align-items: stretch;
  justify-content: center;
  background-color: var(--color-minor-2);
  flex-wrap: wrap;
  box-shadow: 0 1vh 1vh -0.5vh black;
}


.SkillsSection_skillCat__3Km92{
margin: 1vw;
padding: 0vw 1vw 0vw 1vw;
text-align: left;
width: 40vw;
border-radius: 1vw;
background-color: var(--color-minor);
box-shadow: 0vh 0vh 1vh black;
}

@media screen and (min-width: 768px) {
.SkillsSection_skillCat__3Km92{
  width:25vw;
}
}

@media screen and (min-width: 992px) {
.SkillsSection_skillCat__3Km92{
  width:15vw;
}
}

.ProductSection_sectionTitle__2xWUu{
  justify-content: center;
  text-align: left;
  font-size:2em;
  background-color: var(--color-accent-2);
  padding: 1vh 0 1vh 3vw;
  margin: 2vh 0 2vh 0;
  font-weight: bold;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.ProductSection_productSection__2WGOh{
  align-items: left;
  display: flex;
  flex-direction: column;
}

.ProductSection_productItem__2lqQ5{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProductSection_productLeftZone__19sWU{
  width: 30vw;
  min-height:350px;
  display: flex;
  align-items: flex-end;
  background-color: black;
}

.ProductSection_productImg__1llGn {
  max-width: 100%;
  height:auto;
  margin: auto 0 auto auto;
  display: block;
  object-fit: contain;
  background-color: black;
}

.ProductSection_productContent__3RxGu{
  padding-left: 1vw;
  width: 60vw;
  text-align: left;
  border-bottom: var(--color-accent-2) solid 1px;
}

.ProductSection_productLink__3Fn4y{
  color:black;
  text-decoration: none;
}

.ProductSection_productLink__3Fn4y:hover{
  color:var(--color-minor-2);
}

@media screen and (max-width: 768px) {
  .ProductSection_productContent__3RxGu{
    width: 100vw;
  }
  .ProductSection_productLeftZone__19sWU{
    width:100vw;
    height: 30vh;
    align-items: center;
  }
  .ProductSection_productImg__1llGn{
    margin: auto;
    height:100%;
  }
}

.ArtSection_artHeader__1KJw5{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ArtSection_artSection__-sEd3{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.ArtSection_artSection__-sEd3 p{
  text-align: left;
  padding-left: 5vw;
  width: 70%;
}

.ArtSection_artImg__3NrOG{
  max-width: 100%;
  max-height: 60vh;
  margin: auto;
  display: block;
  object-fit: scale-down;
  image-orientation: from-image;
}

.ArtSection_artCollection__1TM0O{
  display: flex;
  flex-direction: row;
  max-width: 90vw;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.ArtSection_artItem__1MmZW{
  width: 30vw;
  height: 100%;
  background-color:  var(--color-minor);
  border-radius: 2vw;
  margin: 1vw;
  box-shadow: 0vh 0vh 2vh black;
}

.ArtSection_collapsible__3G3qq{
  white-space: pre-line;
  margin-top: 1vh;
  width: 90vw;
  box-shadow: 0 1vh 1vh -0.5vh black;
}

.ArtSection_collapsibleOpen__3OX0n{
}

.ArtSection_collapsibleTrigger__1xhMz{
    background-color: var(--color-accent);
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;


}

.ArtSection_collapsibleTrigger__1xhMz:hover{
  background-color: var(--color-accent-2);

}

.ArtSection_collapsibleTriggerOpen__uVtEI{
}

.ArtSection_collapsibleTrigger__1xhMz:after {
  content: '\25BD';
  float: right;
  position: relative;
  left: -2vw;
  display: inline;}

.ArtSection_collapsibleTriggerOpen__uVtEI:after {
  content: '\25B3';
  display: inline; }

.ArtSection_collapsibleOuter__ZIIYU{
  width: 90vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: var(--color-minor-2);
  margin: auto;
}

.ArtSection_collapsibleInner__qk9o7{
}

@media screen and (max-width: 768px) {
  .ArtSection_artItem__1MmZW{
    width:80vw;
  }
  .ArtSection_artImg__3NrOG{
    height:50vh;
  }
}

.CocktailSection_cocktailSection__N2JNy{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.CocktailSection_cocktailImg__3nNnk{
}
.CocktailSection_cocktailCategory__115cE{
}
.CocktailSection_cocktailItem__2yzQK{
  width:40vw;
}

.CocktailSection_cocktailComponentList__2yMo2{
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-basis: min-content;
}

.CocktailSection_collapsible__1ubzW{
}

.CocktailSection_collapsibleOpen__2FOso{
}

.CocktailSection_collapsibleTrigger__3dUqP{
}

.CocktailSection_collapsibleTriggerOpen__1CVLW{
}

.CocktailSection_collapsibleTrigger__3dUqP:after {
  content: '\25BD';
  display: inline;}

.CocktailSection_collapsibleTriggerOpen__1CVLW:after {
  content: '\25B3';
  display: inline; }

.CocktailSection_collapsibleOuter__2yqkm{
}

.CocktailSection_collapsibleInner__2LSEH{
}

@media screen and (max-width: 768px) {
  .CocktailSection_cocktailItem__2yzQK{
    width:80vw;
  }
}

.navbar_navBar__19PXJ {
    width: 100%;
    background-color: var(--color-accent);
    text-align: center;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 1vh 1vh -0.5vh black;
}

nav li {
  padding: 1vh 1vw;
  color: black;
}

nav a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

nav li:hover {
  background: var(--color-accent-2);
  color: white;
}

button {
    font-size: 1.5em;
    color: black;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    height: auto;
    padding: 2vh;
}

button:hover{
  background-color: var(--color-accent-2);
  color:white;
}


.navbar_links__3k7aG {
  height: 0;
  width: 90vw;
  overflow: hidden;
  list-style-type: none;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin: 0;

}

.navbar_showLinks__1Wnl5{
  height:auto;
}


@media screen and (min-width: 768px) {
    button {
        display: none;
    }

    .navbar_links__3k7aG {
        height: auto;
        flex-direction: row;
        width: auto;
    }
    nav li{
      padding: 1vh 3vw;
    }

}

:root{
    --color-accent:#00CCAA;
    --color-accent-2: #ED1C24;
    --color-minor: #77C9F8;
    --color-minor-2:#F1F2F3;
/*     --color-minor-2:#F1D302;*/
}

body{
  font-size: 1em;
  font-family: 'Nunito', sans-serif;
}

p{
  padding: 0 0 1vh 1vw;;
  margin:0;
}
h1{
  justify-content: center;
  text-align: center;
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

h2{
    justify-content: center;
    text-align: center;
    font-size:2em;
    font-weight: bold;
}

h3{
    justify-content: center;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}

.social{
  display:inline-flex;
}


@media screen and (max-width: 768px) {
  body{
    font-size: 2.8vw;
  }
}

