.banner{
  display:flex;
  background-color: RoyalBlue;
  align-items: center;
  justify-content: center;
  background-image: url("../images/me_filter_1.jpg");
  background-size: 20vw;
  background-repeat: repeat;
  height: 60vh;
  box-shadow: 0 1vh 1vh -0.5vh black;
  margin: 0 0 1vh 0;
}

.headText{
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1vh;
  margin: 1vh;
  color: white;
  background-color: var(--color-accent-2);
  border-radius: 1vh;
  box-shadow: 0vh 0vh 2vh 1vh black;
}


@media screen and (max-width: 768px) {
  .banner{
    background-size: 15vw;
    height: 40vh;
  }

}
