:root{
    --color-accent:#00CCAA;
    --color-accent-2: #ED1C24;
    --color-minor: #77C9F8;
    --color-minor-2:#F1F2F3;
/*     --color-minor-2:#F1D302;*/
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap');

body{
  font-size: 1em;
  font-family: 'Nunito', sans-serif;
}

p{
  padding: 0 0 1vh 1vw;;
  margin:0;
}
h1{
  justify-content: center;
  text-align: center;
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

h2{
    justify-content: center;
    text-align: center;
    font-size:2em;
    font-weight: bold;
}

h3{
    justify-content: center;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}

.social{
  display:inline-flex;
}


@media screen and (max-width: 768px) {
  body{
    font-size: 2.8vw;
  }
}
